define("discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class FieldDescription extends _component.default {}
  _exports.default = FieldDescription;
  _class = FieldDescription;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @description}}
        <p class="control-description">
          {{@description}}
        </p>
      {{/if}}
    
  */
  {
    "id": "r14NfgRU",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,2],[14,0,\"control-description\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@description\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-field-description.js",
    "isStrictMode": true
  }), _class);
});