define("discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class FieldLabel extends _component.default {}
  _exports.default = FieldLabel;
  _class = FieldLabel;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @label}}
        <label class="control-label">
          <span>
            {{@label}}
            {{#if @field.isRequired}}
              *
            {{/if}}
          </span>
        </label>
      {{/if}}
    
  */
  {
    "id": "p/Zsrmun",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n\"],[41,[30,2,[\"isRequired\"]],[[[1,\"            *\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@label\",\"@field\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-field-label.js",
    "isStrictMode": true
  }), _class);
});